// Theme Slow88

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset26 = createTheme({
  name: "preset26",
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 140,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 120,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 1,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#9c27b0", 0.4),
    // สีหลัก (สีม่วงดำ)
    primary: {
      main: "#c1a447",
      contrastText: "#ffffff",
    },
    // สีลอง (ดำขาว)
    secondary: {
      main: "#E82E81",
      // main: "#ff0040",672e8a
      contrastText: "#000000",
    },
    background: {
      // สีพื้นหลัง
      default: "#000000e3",
      // สี Card
      paper: "#a41454",
    },   
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: undefined,
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
    bodySize: "contain",
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#f9688d",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#a41454",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "#E82E81",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(330deg, rgba(160,198,22,1) 32%, rgba(127,158,12,1) 100%)",
    // สี Card
    300: "#242424f0",
    // สี Appbar
    400: "#E82E81",
    // สี Mainmenu
    410: "linear-gradient(203deg, rgb(230, 195, 97) 21%, rgb(184, 151, 59) 50%, rgb(157, 125, 37) 75%, rgb(184, 151, 59) 100%)",
    // สีปุ่ม Deposit
    500: "linear-gradient(344deg, rgb(0 0 0) 0%, rgb(93 82 82) 40%, rgb(0 0 0) 100%)",
    // สีปุ่ม Withdraw
    600: "linear-gradient(197deg, #BF953F, #f1dc98, #B38728)",
    // สี Tag
    700: "#e82e81",
    // สี Logo FooterNav
    800: "#ffeffdb5",
    // สี Text ทั้งหมด
    900: "#ffffff",
    // สี Text 2
    910: "#f9de9b",
    // สี Text ทั้งหมด
    920: "#ffffff",
    // สี Bg myDeposit
    930: "#fff4e5"

    // #c1a447
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#810136",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#ffa4b4",
      // สีກົງລໍ້ 2
      fillStyle2: "#ba004d",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset26.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: "white",
            borderColor: preset26.palette.primary.main,
            "&:hover": {
              borderColor: "#b49ac7",
            },
          }),
        }),
      },
    },
  },
});

export default preset26;
