// Theme Slow88

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset18 = createTheme({
  name: "preset18",
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 100,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 14,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#9c27b0", 0.4),
    // สีหลัก (สีม่วงดำ)
    primary: {
      main: "#b756ff",
      contrastText: "#FFFFFF",
    },
    // สีลอง (ดำขาว)
    secondary: {
      main: "#672e8a",
      // main: "#ff0040",672e8a
      contrastText: "#000000",
    },
    background: {
      // สีพื้นหลัง
      default: "#0c0330",
      // สี Card
      paper: "#0c0330",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "https://images-platform.sgp1.cdn.digitaloceanspaces.com/logo/photo_2024-04-02_00-34-56.jpg",
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: 0.7,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#1e0d63",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#080221",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(90deg, #672e8c 30%, #977fb2 90%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(330deg, rgba(160,198,22,1) 32%, rgba(127,158,12,1) 100%)",
    // สี Card
    300: "#0c0330",
    // สี Appbar
    400: "#0c0330",
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#20144a",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#ba68c8",
      // สีກົງລໍ້ 2
      fillStyle2: "#0c0330",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset18.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: "white",
            borderColor: preset18.palette.primary.main,
            "&:hover": {
              borderColor: "#b49ac7",
            },
          }),
        }),
      },
    },
  },
});

export default preset18;
