import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset16 = createTheme({
  name: 'preset16',
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 100,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 0,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#1a2c38", 0.4),
    primary: {
      // สีหลัก
      main: "#fff",
      // สีຕົວອັກສອນ
      contrastText: "#1a2c38",
    },
    secondary: {
      // สีรอว
      main: "#fff",
      // สีຕົວອັກສອນ
      contrastText: "#fff",
    },
    background: {
      // สีพื้นหลัง
      default: "#1a2c38",
      // สี Card
      paper: "#1f2937",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: undefined,
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#1f2937",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#1f2937",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: '#0f212e',
    // // สีกระเป๋าตอนรับโบนัส
    200: '#0f212e',
    // // สี Card
    300: '#1a2c38',
    // // สี Appbar
    400: '#1a2c38'
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#ffa726",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#ffa726",
      // สีກົງລໍ້ 2
      fillStyle2: "#1f2937",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              background: preset16.palette.primary.main,
            }),
          ...(ownerState.variant === "outlined" && {
            color: preset16.palette.primary.main,
            borderColor: preset16.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset16;
