import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset14 = createTheme({
  name: "preset14",
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: true,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 120,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 120,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 0,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#790e50", 0),
    // สีหลัก
    primary: {
      main: "#ffffff",
      contrastText: "#790e50",
    },
    // สีลอง
    secondary: {
      main: "#fe8eaf",
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#fdd2e3",
      // สี Card
      paper: "#f06ebc",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: 'https://images-platform.sgp1.cdn.digitaloceanspaces.com/background/tiga98.jpg',
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: 0.5,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#f06ebc",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#fdd2e3",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "#fdd2e3",
    // สีกระเป๋าตอนรับโบนัส
    200: "#fdd2e3",
    // สี Card
    300: "linear-gradient(180deg, #FE6BAF, #B17FFC)",
    // สี Appbar
    400: "linear-gradient(180deg, #FE6BAF, #B17FFC)",
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#790e50",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#f06ebc",
      // สีກົງລໍ້ 2
      fillStyle2: "#fdd2e3",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset14.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset14.palette.primary.main,
            borderColor: preset14.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset14;
