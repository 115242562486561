// Theme THX777

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset13 = createTheme({
  name: "preset13",
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 60,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 60,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 10,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#e6bd5d", 1),
    // สีหลัก
    primary: {
      main: "#d5a43e",
      contrastText: "#171203",
    },
    // สีลอง
    secondary: {
      main: "#171203",
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#23551E",
      // สี Card
      paper: "#23551E",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: 'https://images-platform.sgp1.cdn.digitaloceanspaces.com/logo/slotpgbetflix-bg.jpg',
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
    bodySize: 'cover'
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#d5a43e",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#23551E",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(180deg, #23551E 33%, #111812 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(180deg, #1db617 50%, #95e92f 100%)",
    // สี Card
    300: "#103116",
    // สี Appbar
    400: "linear-gradient(180deg, #23551E 0%, #111812 100%)",
    920: "#d5a43e",
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#f7d16f",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#d5a43e",
      // สีກົງລໍ້ 2
      fillStyle2: "#23551E",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset13.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset13.palette.primary.main,
            borderColor: preset13.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset13;
