// Theme LuckyGirl678

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset6 = createTheme({
  name: "preset6",
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 80,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 80,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 14,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#e6c361", 0.4),
    // สีหลัก
    primary: {
      main: "#fbed56",
      contrastText: "#171203",
    },
    // สีลอง
    secondary: {
      main: "#86b84a",
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#01210a",
      // สี Card
      paper: "#01210a",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: undefined,
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#1e0d63",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#080221",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(330deg, rgba(134,184,74,1) 32%, rgba(76,133,9,1) 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(45deg, #7623a8 30%, #a464eb 90%)",
    // สี Card
    300: "#01210a",
    // สี Appbar
    400: "#01210a",
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#1c2b02",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#fbed56",
      // สีກົງລໍ້ 2
      fillStyle2: "#86b84a",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset6.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset6.palette.primary.main,
            borderColor: preset6.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset6;
