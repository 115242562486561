// Theme LuckyGirl678

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset15 = createTheme({
  name: "preset15",
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 80,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 80,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 14,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#ffffff", 0.4),
    // สีหลัก
    primary: {
      main: "#fff",
      contrastText: "#171203",
    },
    // สีลอง
    secondary: {
      main: "#51155b",
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#51155b",
      // สี Card
      paper: "#51155b",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "https://images-platform.sgp1.cdn.digitaloceanspaces.com/background/simple-bg.png",
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
    // ขนาดรูปพื้นหลัง
    bodySize: "cover",
    // รูปแบบการวางรูปพื้นหลัง
    backgroundAttachment: "fixed",
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#51155b",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#51155b",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(#148719, #042705)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%)",
    // สี Card
    300: "#51155b",
    // สี Appbar
    400: "transparent",
    // สี Mainmenu
    500: "linear-gradient(180deg, rgba(224,181,208,1) 0%, rgba(81,21,91,1) 90%)",
    610: "linear-gradient(180deg, rgba(224,181,208,1) 0%, rgba(81,21,91,1) 90%)",
    620: "radial-gradient(circle, rgba(81,21,91,1) 0%, rgba(224,181,208,1) 4%, rgba(81,21,91,1) 90%)",
    // สี Tag
    700: "#ffffff",
    920: "#fff",
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#b1acaf",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#e0b5d0",
      // สีກົງລໍ້ 2
      fillStyle2: "#51155b",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset15.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset15.palette.primary.main,
            borderColor: preset15.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset15;
