// Theme THX777

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset27 = createTheme({
  name: "preset27",
  config: {
    // แสดงปุ่มຝາກຖອນในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 100,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 5,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#000000", 0.4),
    primary: {
      // สีหลัก
      main: "#000000",
      // สีຕົວອັກສອນ
      contrastText: "#fff",
    },
    secondary: {
      // สีรอว
      main: "#a00000",
      // สีຕົວອັກສອນ
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#000000",
      // สี Card
      paper: "#90161a",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: undefined,
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#3d1516",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#c3c3c3",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(337deg, rgba(255, 0, 0, 1) 0%, rgba(128, 0, 0, 1) 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(344deg, rgba(195,34,49,1) 20%, rgba(253,187,45,1) 100%)",
    // สี Card
    300: "linear-gradient(337deg, rgba(255, 0, 0, 1) 0%, rgba(128, 0, 0, 1) 100%)",
    // สี Appbar
    400: "linear-gradient(337deg, rgba(255, 0, 0, 1) 0%, rgba(128, 0, 0, 1) 100%)",
    // สีปุ่ม Deposit
    500: "linear-gradient(0deg, rgba(3,107,34,1) 0%, rgba(0,255,76,1) 100%)",
    // สีปุ่ม Withdraw
    600: "linear-gradient(330deg, rgba(133,6,6,1) 0%, rgba(193,0,0,1) 40%, rgba(255,36,36,1) 100%)",
    // สีปุ่ม GameMenu
    610: "#a00000",
    // สี Tag
    700: "#ffffff",    
    // สี Logo FooterNav
    800: "#ffffff",
    // สี Text MainMenu
    920: "#ffffff",
  },
  wheel: {
    // ขอบກົງລໍ້
    borderColor: "#000000",
    segment: {
      // สีກົງລໍ້ 1
      fillStyle1: "#ffffff",
      // สีກົງລໍ້ 2
      fillStyle2: "#000000",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset27.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset27.palette.primary.main,
            borderColor: preset27.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset27;
